import React from "react";
import "../../styles/custom.css";

function Wallet() {
  

  return (
    <section className="order">
     <h1 className="d-flex justify-content-center align-items-center p-5 m-5 ">Coming soon...</h1>
    </section>
  );
}

export default Wallet;
